@import '../../Style/colors.scss';

.contactForm {
  width: 400px;
  margin: 0 auto;

  .intro {
    font-size: 18px;
    text-align: center;
    font-style: italic;
    color: white;
    margin-bottom: 24px;
  }

  .inputGroup {
    width: 100%;
    padding: 12px 0;

    label {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
    }

    input[type="text"], input[type="email"] {
      display: block;
      width: 100%;
      margin-top: 6px;
      height: 36px;
      background-color: $lime;
      outline: none;
      border: none;
      font-size: 16px;
      padding: 4px 8px;
      font-style: italic;
      font-weight: bold;
    }

    textarea {
      display: block;
      width: 100%;
      margin-top: 6px;
      height: 72px;
      background-color: $lime;
      outline: none;
      border: none;
      font-size: 16px;
      padding: 4px;
      font-style: italic;
      font-weight: bold;
    }

    button[type="submit"] {
      background-color: $purple;
      outline: none;
      border: none;
      color: white;
      font-weight: bold;
      font-size: 20px;
      padding: 16px 24px;

      &:hover, &:active {
        background-color: rgba(102, 43, 145, 0.8);
        cursor: pointer;
      }
    }
  }
}