@import '../../Style/colors.scss';

.wrapper {
  width: 100%;
  background-color: $purple;
  text-align: center;
  position: fixed;
  top: 0;
  opacity: 0.8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 1000;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
}

.navigation {
  width: 1024px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 150px;
  position: relative;
  top: -10px;
}

.navLink {
  padding: 10px 18px;
  color: white;
  height: 100px;
  line-height: 80px;
  font-size: 20px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1.5px;
  transition: all ease-in-out 0.1s;
  opacity: 0.8;
  margin: 0 16px;
  width: 20%;

  &.homeLink {
    display: none;
  }

  &.navLogo {
    opacity: 1;
  }

  &.navContact {
    width: 40%;
  }
  
  &:hover:not(.navLogo), &.navLinkActive {
    opacity: 1;
    border-bottom: 4px solid white;
  }
}

.mobileLogoWrapper {
  display: none;
}

@media all and (max-width: 980px) {
  .navLink {
    font-size: 18px;
    letter-spacing: 1.25px;
    padding: 10px 4px;
    margin: 0 10px;
  }
}

@media all and (max-width: 800px) {
  .navLink {
    font-size: 16px;
    letter-spacing: 1px;
    padding: 10px 0;
    margin: 0 4px;
  }
}

@media all and (max-width: 580px) {
  .navigation {
    flex-direction: row;
  }

  .navLink {
    font-size: 14px;
    display: inline-block;
    width: 25% !important;
    opacity: 1;
    padding: 12px 0;
    line-height: 20px;
    height: auto;

    &.homeLink {
      display: block;
    }

    &.navLogo {
      display: none;
    }
    
    &:hover:not(.navLogo), &.navLinkActive {
      border-bottom: none;
    }
  }
}
