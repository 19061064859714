@import '../../Style/colors.scss';

p {
  padding: 6px 0;
  line-height: 26px;
  font-size: 18px;
}

.highlight {
  border-bottom: 1px dashed $lime;
  font-weight: bold;
}

.purpImage {
  float: right;
  width: 300px;
  margin: 24px;
  border: 8px $purple solid;
}

.lastEdited {
  font-size: 12px;
  font-style: italic;
}