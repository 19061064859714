@import '../../Style/colors.scss';

.testimonialsWrapper {

  .testimonialsContainer {
    width: 1024px;
    max-width: 100%;
    margin: 0 auto;

    .testimonialsSubtitle {
      text-align: center;
      font-style: italic;
      font-size: 32px;
      margin: 70px 0;
      color: $lime;
    }

    .testimonialsBody {
      text-align: center;

      .testimonial {
        display: inline-block;
        width: 400px;
        background-color: #efefef;
        color: #111;
        margin: 0 24px;
        opacity: 0.8;
        border: 4px $purple solid;

        .body {
          font-size: 20px;
          padding: 24px 24px 0;
          line-height: 26px;
          font-style: italic;
        }

        .author {
          text-align: right;
          padding: 24px;
          font-size: 18px;
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .testimonialsWrapper {
    padding: 24px;
  }

  .testimonial {
    width: 100% !important;
    margin: 0 0 12px !important;
  }
}