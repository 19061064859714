@import '../../Style/colors.scss';

.featuredImage {
  background-image: url('./../../../public/steak-sandwich-1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 800px;
  position: relative;
  border-bottom: $purple solid 4px;

  .overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .overlayContainer {
      position: relative;
      width: 1024px;
      max-width: 100%;
      margin: 0 auto;
      height: 100%;

      .titleContent {
        margin-top: 700px;

        .title {
          background-color: $purple;
          font-weight: bold;
          font-style: italic;
          font-size: 60px;
          padding: 18px 30px;
          opacity: 0.75;
          box-shadow: 0px 3px 15px rgba(0,0,0,0.3);
          text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
          display: inline-block;
          margin-bottom: 12px;
        }
    
        .subtitle {
          background-color: $lime;
          font-weight: bold;
          font-style: italic;
          font-size: 34px;
          padding: 12px 24px;
          opacity: 0.75;
          box-shadow: 0px 3px 15px rgba(0,0,0,0.3);
          text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
          display: inline-block;
          margin-bottom: 26px;
        }
  
        .ourStoryLink, .menuLink {
          font-size: 20px;
          color: white;
          background-color: #111111;
          bottom: 215px;
          width: auto;
          padding: 12px 24px;
          text-decoration: none;
          opacity: 0.75;
          transition: all ease-in-out 0.3s;
          font-weight: bold;
  
          &:hover {
            opacity: 1;
          }
        }
  
        .ourStoryLink {

        }
  
        .menuLink {

        }
      }
    }
  }
}

@media all and (min-width: 800px) {
  .featuredImage {
    height: 800px;
  }
}

@media all and (min-width: 1000px) {
  .featuredImage {
    height: 900px;
  }
}

@media all and (min-width: 1200px) {
  .featuredImage {
    height: 1000px;
  }
}


@media all and (max-width: 1200px) {
  .titleContent {
    margin-top: 600px !important;
  }
}

@media all and (max-width: 1024px) {
  .overlayContainer {
    width: 100%;

    .titleContent {
      margin-left: 12px;
    }
  }
}

@media all and (max-width: 1000px) {
  .titleContent {
    margin-top: 500px !important;
    margin-left: 12px;
  }
}

@media all and (max-width: 840px) {
  .titleContent {
    .title {
      font-size: 48px !important;
    }
    .subtitle {
      font-size: 26px !important;
    }
    .ourStoryLink, .menuLink {
      font-size: 18px !important;
    }
  }
}

@media all and (max-width: 690px) {
  .titleContent {
    margin-top: 550px !important;

    .title {
      font-size: 42px !important;
    }
    .subtitle {
      font-size: 22px !important;
    }
    .ourStoryLink, .menuLink {
      font-size: 16px !important;
    }
  }
}

@media all and (max-width: 610px) {
  .titleContent {

    .title {
      font-size: 36px !important;
      margin-bottom: 8px !important;
    }
    .subtitle {
      font-size: 20px !important;
      margin-bottom: 22px !important;
    }
    .ourStoryLink, .menuLink {
      font-size: 16px !important;
    }
  }
}

@media all and (max-width: 535px) {
  .titleContent {

    .title {
      font-size: 30px !important;
    }
    .subtitle {
      font-size: 18px !important;
    }
    .ourStoryLink, .menuLink {
      font-size: 16px !important;
    }
  }
}

@media all and (max-width: 460px) {
  .titleContent {

    .title {
      display: block;
      font-size: 26px !important;
      margin-bottom: 8px !important;
      text-align: center;
      padding: 12px 24px !important;
    }
    .subtitle {
      font-size: 16px !important;
      padding: 8px 20px !important;
    }
    .ourStoryLink, .menuLink {
      font-size: 14px !important;
      padding: 8px 20px !important;
    }
  }
}

@media all and (max-width: 450px) {
  .featuredImage {
    height: 500px !important;

    .titleContent {
      margin-top: 300px !important;

      .title {
        padding: 8px 18px !important;
      }
    }
  }
}