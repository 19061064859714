@import '../../Style/colors.scss';

.menuWrapper {
  text-align: center;

  h2 {
    margin-top: 36px;
    margin-bottom: 24px;
    width: 600px;
    max-width: 100%;

    .sectionHeaderBody {
      display: inline-block;
      background-color: $purple;
      padding: 12px 18px;
      font-size: 28px;
      transform: skew(-10deg);
    }
  }
}

ul {
  width: 600px;
  max-width: 100%;
  display: inline-block;
  text-align: left;

  li {
    border-bottom: 1px $lime dotted;
    padding: 6px 0;
    font-size: 22px;
  }
}
