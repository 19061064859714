body {
  background-color: #111;
  color: #efefef;
}

.contentWrap {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
}

.contentBody {
  width: 1024px;
  max-width: 100%;
  padding: 80px 24px 24px;
}

@media all and (max-width: 580px) {
  .contentWrap {
    
  }
  .contentBody {
    padding-top: 0 !important;
  }
}